import { graphql, useStaticQuery } from 'gatsby';

export default function useCdnUrl() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            cdnBaseUrl
            cdnBaseUrlTest
          }
        }
      }
    `
  );
  const cdnBaseUrl =
    process.env.GATSBY_ENV === 'development'
      ? site.siteMetadata.cdnBaseUrlTest
      : site.siteMetadata.cdnBaseUrl;

  return cdnBaseUrl;
}
