import React from 'react';
import SectionLayout from '../../layouts/SectionLayout';
import servicesOverview from '../../images/cai_services_overview.png';
import SEO from '../../components/SEO';
import useCdnUrl from '../../components/useCdnUrl';

export default function Services() {
  const cdnBaseUrl = useCdnUrl();

  return (
    <>
      <SEO title="Services" />
      <SectionLayout>
        <p className="section-display-subheader">
          YOUR DIGITAL TRANSFORMATION. DELIVERED.
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-6">
            <p>
              {`
              With our services staff expertise in IT and GIS, we'll help you
              transform digitally, both in-field and in-office.`}
            </p>
            <p>
              Using the latest, high-resolution drone and satellite technology,
              our process begins by capturing the most detailed imagery of your
              Ag operation, and we build missing GIS maps to create a digital
              inventory that includes all of your facilities, assets, and crops.
            </p>
          </div>
          <div className="col-sm-6">
            <p>
              We combine this data, creating quantifiable insights that help you
              improve your management practices and overall product performance.
            </p>
            <p>
              {`Our services come with the backend support and infrastructure to
              scale, monitor and maintain your data, no matter the size of your
              operation. There's no need for you to do any extra work, hire or
              train new staff.`}
            </p>
          </div>
        </div>
        <div className="text-center">
          <a href={`${cdnBaseUrl}marketing/services/CAI_CAP_OnePager.pdf`}>
            Learn about our CultivateAI Advantage Program (CAP) here
          </a>
        </div>
        <div className="row mobile-desktop-img-margin">
          <div className="col-sm-12 text-center">
            <img
              className="img-fluid"
              alt="gis mapping services overview img"
              src={servicesOverview}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
